/** @jsx jsx */
import styled from "@emotion/styled"
import { jsx } from "theme-ui"
import Img from "gatsby-image"

const imgStyles = {
  css: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    transition: "transform 0.5s, filter 0.25s",
    "&:hover": {
      transform: "scale(1.05)",
      filter: "saturate(1.3)",
    },
  },
}

const Tile = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
`

export default ({ image, onClick, style }) => (
  <Tile name={image.name} onClick={onClick}>
    <Img
      alt={image.name}
      fluid={image.fluid}
      src={image.src}
      css={style ? { ...imgStyles.css, ...style } : imgStyles.css}
    />
    <span
      style={{
        position: "absolute",
        bottom: `4px`,
        left: `4px`,
        fontSize: "13px",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        background: "rgba(0,0,0,0.5)",
        borderRadius: "2px",
        padding: "2px 4px",
        lineHeight: "1",
      }}
    >
      {image.name}
    </span>
  </Tile>
)

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styled from "@emotion/styled"

const Wrapper = styled.div`
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`

const About = styled.h3`
  margin: 0;
  @media (max-width: 800px) {
    margin-top: 2rem;
  }
`

const Header = ({ siteTitle }) => (
  <header
    style={{
      // background: `rebeccapurple`,
      margin: `6rem 0 2rem 0`,
    }}
  >
    <Wrapper
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `2rem 1.0875rem`,
        display: "flex",
      }}
    >
      <h1 style={{ margin: 0, textAlign: "center" }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <About>
        <Link
          to="/about"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          About
        </Link>
      </About>
    </Wrapper>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

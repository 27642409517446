/** @jsx jsx */
import * as React from "react"
import styled from "@emotion/styled"
import { jsx } from "theme-ui"
import { Lightbox } from "react-modal-image"
import useGallery from "gatsby-theme-gallery/src/hooks/useGallery"
import GalleryItem from "./galleryItem"

const Grid = styled.div(({ size }) => ({
  display: "grid",
  gridTemplateColumns:
    size === "small"
      ? "repeat(auto-fit, minmax(16rem, 1fr))"
      : "repeat(auto-fit, minmax(24rem, 1fr))",
  gridAutoRows: size === "small" ? "24rem" : "36rem",
  gridGap: "0.5rem",
}))

function getLastName(name) {
  const split = name.split(" ")

  let lastName = split[1]

  if (lastName[0].toLowerCase() === lastName[0]) {
    return split[2]
  } else {
    return lastName
  }
}

const Gallery = ({ images, gridSize = "small", sort = true }) => {
  const [showImageIndex, setShowImageIndex] = React.useState(undefined)

  const sorted = sort
    ? images.sort((a, b) => {
        const { name: aName } = a
        const { name: bName } = b

        const aLastName = getLastName(aName)
        const bLastName = getLastName(bName)

        console.log(aLastName, bLastName)

        return (aLastName || "").localeCompare(bLastName)
      })
    : images

  return (
    <div>
      <Grid size={gridSize}>
        {sorted.map((image, index) => (
          <GalleryItem
            key={image.id}
            image={image}
            onClick={() => {
              setShowImageIndex(index)
            }}
          />
        ))}
      </Grid>
      {showImageIndex !== undefined && (
        <Lightbox
          hideDownload={true}
          large={sorted[showImageIndex].publicURL}
          onClose={() => {
            setShowImageIndex(undefined)
          }}
        />
      )}
    </div>
  )
}

export default Gallery
